import React, { useEffect } from "react";
import { HashRouter,BrowserRouter, Route, Switch } from "react-router-dom";


import App from "./App";
import MobileAppRoot from "./app/MobileApp/MobileAppRoot";

function RootFileRouter() {

  return (
    <>
      <Switch>
          
          <Route path={"/"} exact={true} component={App} />
          <Route path={"/swapp"} exact={true} component={MobileAppRoot} />
          {/* <Route component={NotFound} exact={true} /> */}
         
        </Switch>
    </>)
}


export default RootFileRouter;